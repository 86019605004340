// requesting and falure handeling
export const ISLOADING = "ISLOADING";
export const REPORT_REQUEST = "REPORT_REQUEST";
export const REPORT_FAILURE = "REPORT_FAILURE";

// Sale Report
export const GET_SALE_REPORT_SUCCESS = "GET_SALE_REPORT_SUCCESS";

// Purchase Report
export const GET_PURCHASE_REPORT_SUCCESS = "GET_PURCHASE_REPORT_SUCCESS";

// Day Books
export const GET_DAYBOOK_SUCCESS = "GET_DAYBOOK_SUCCESS";

// All Transactions
export const GET_ALL_TRANSACTIONS_SUCCESS = "GET_ALL_TRANSACTIONS_SUCCESS";

// export const GET_PAYOUTBILL_SUCCESS = "GET_PAYOUTBILL_SUCCESS";
// export const GET_PURCHASEORDER_SUCCESS = "GET_PURCHASEORDER_SUCCESS"
// export const GET_PURCHASERETURN_SUCCESS = "GET_PURCHASERETURN_SUCCESS";
