// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.FirstTimeFormToggleCss_OuterDiv__ybrzV {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: default;
  background: var(--pureWhite);
  border-radius: 6px;
}
.FirstTimeFormToggleCss_ImgDiv__uoDWA {
  margin: auto;
  width: 250px;
  height: 250px;
  background: transparent;

  img {
    width: 100%;
    height: 100%;
    display: block;
    background: transparent;
    mix-blend-mode: multiply;
  }
}
.FirstTimeFormToggleCss_OuterDiv__ybrzV p {
  color: var(--greyA);
  font-size: 16px;
  word-spacing: 0.5px;
  margin: auto;
  text-align: center;
}
.FirstTimeFormToggleCss_OuterDiv__ybrzV button {
  display: block;
  cursor: pointer;
  margin: 30px auto;
  width: -moz-fit-content;
  width: fit-content;
  border: none;
  font-size: 18px;
  color: white;
  background: var(--yellowA);
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  border-radius: 5px;
  padding: 10px 20px;
}
`, "",{"version":3,"sources":["webpack://./src/Component/FirmTimeForm/FirstTimeFormToggleCss.module.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,eAAe;EACf,4BAA4B;EAC5B,kBAAkB;AACpB;AACA;EACE,YAAY;EACZ,YAAY;EACZ,aAAa;EACb,uBAAuB;;EAEvB;IACE,WAAW;IACX,YAAY;IACZ,cAAc;IACd,uBAAuB;IACvB,wBAAwB;EAC1B;AACF;AACA;EACE,mBAAmB;EACnB,eAAe;EACf,mBAAmB;EACnB,YAAY;EACZ,kBAAkB;AACpB;AACA;EACE,cAAc;EACd,eAAe;EACf,iBAAiB;EACjB,uBAAkB;EAAlB,kBAAkB;EAClB,YAAY;EACZ,eAAe;EACf,YAAY;EACZ,0BAA0B;EAC1B,iDAAiD;EACjD,kBAAkB;EAClB,kBAAkB;AACpB","sourcesContent":[".OuterDiv {\n  width: 100%;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  cursor: default;\n  background: var(--pureWhite);\n  border-radius: 6px;\n}\n.ImgDiv {\n  margin: auto;\n  width: 250px;\n  height: 250px;\n  background: transparent;\n\n  img {\n    width: 100%;\n    height: 100%;\n    display: block;\n    background: transparent;\n    mix-blend-mode: multiply;\n  }\n}\n.OuterDiv p {\n  color: var(--greyA);\n  font-size: 16px;\n  word-spacing: 0.5px;\n  margin: auto;\n  text-align: center;\n}\n.OuterDiv button {\n  display: block;\n  cursor: pointer;\n  margin: 30px auto;\n  width: fit-content;\n  border: none;\n  font-size: 18px;\n  color: white;\n  background: var(--yellowA);\n  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;\n  border-radius: 5px;\n  padding: 10px 20px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"OuterDiv": `FirstTimeFormToggleCss_OuterDiv__ybrzV`,
	"ImgDiv": `FirstTimeFormToggleCss_ImgDiv__uoDWA`
};
export default ___CSS_LOADER_EXPORT___;
