export const ISLOADING = "ISLOADING";
export const ISERROR = "ISERROR";
export const SUCCESS = "SUCCESS";

// Delete Firm
export const DELETE_FIRM_LOADING = "DELETE_FIRM_LOADING";
export const DELETE_FIRM_ERROR = "DELETE_FIRM_ERROR";
export const DELETE_FIRM_SUCCESS = "DELETE_FIRM_SUCCESS";

export const SET_CURRENT_COMPANY = "SET_CURRENT_COMPANY";
export const UPDATE_PROFILE_SUCCESS = "UPDATE_PROFILE_SUCCESS";
export const FETCH_COMPANIES_SUCCESS = "FETCH_COMPANIES_SUCCESS";
export const INPUTCHANGE = "INPUTCHANGE";
