import css from "./Loaders.module.css";

const Loader4 = () => {
  return (
    <div className={css.Loader4Outer}>
      <span className={css.loader4}></span>
    </div>
  );
};

export default Loader4;
