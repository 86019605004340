// Add Puchase Bill
export const ADD_PURCHASE_LOADING = "ADD_PURCHASE_LOADING";
export const ADD_PURCHASE_ERROR = "ADD_PURCHASE_ERROR";
export const ADD_PURCHASE_SUCCESS = "ADD_PURCHASE_SUCCESS";

// Get All Puchase Bill
export const GET_ALL_PURCHASE_BILL_LOADING = "GET_ALL_PURCHASE_BILL_LOADING";
export const GET_ALL_PURCHASE_BILL_ERROR = "GET_ALL_PURCHASE_BILL_ERROR";
export const GET_ALL_PURCHASE_BILL_SUCCESS = "GET_ALL_PURCHASE_BILL_SUCCESS";

// Get All Payment Out
export const GET_ALL_PAYMENT_OUT_LOADING = "GET_ALL_PAYMENT_OUT_LOADING";
export const GET_ALL_PAYMENT_OUT_ERROR = "GET_ALL_PAYMENT_OUT_ERROR";
export const GET_ALL_PAYMENT_OUT_SUCCESS = "GET_ALL_PAYMENT_OUT_SUCCESS";

// Get All Payment Out
export const GET_ALL_PURCHASE_ORDER_LOADING = "GET_ALL_PURCHASE_ORDER_LOADING";
export const GET_ALL_PURCHASE_ORDER_ERROR = "GET_ALL_PURCHASE_ORDER_ERROR";
export const GET_ALL_PURCHASE_ORDER_SUCCESS = "GET_ALL_PURCHASE_ORDER_SUCCESS";

// Get All Purchase Returns
export const GET_PURCHASE_RETURN_LOADING = "GET_PURCHASE_RETURN_LOADING";
export const GET_PURCHASE_RETURN_ERROR = "GET_PURCHASE_RETURN_ERROR";
export const GET_PURCHASE_RETURN_SUCCESS = "GET_PURCHASE_RETURN_SUCCESS";

// ---------- OLD ---------------
// requesting and falure handeling
export const ISLOADING = "ISLOADING";
export const PURCHASE_REQUEST = "PURCHASE_REQUEST";
export const PURCHASE_FAILURE = "PURCHASE_FAILURE";

// for getting PURCHASE BILLS
export const GET_PURCHASEBILL_SUCCESS = "GET_PURCHASEBILL_SUCCESS";
export const GET_SINGLE_PURCHASEBILL_SUCCESS =
  "GET_SINGLE_PURCHASEBILL_SUCCESS";
export const GET_PAYOUTBILL_SUCCESS = "GET_PAYOUTBILL_SUCCESS";

// for posting NEW BILLS
export const POST_PURCHASEBILL_SUCCESS = "POST_PURCHASEBILL_SUCCESS";
export const POST_PURCHASEORDER_SUCCESS = "POST_PURCHASEORDER_SUCCESS";
export const POST_PURCHASERETURN_SUCCESS = "POST_PURCHASERETURN_SUCCESS";
export const POST_PAYOUT_SUCCESS = "POST_PAYOUTOUT_SUCCESS";

export const DELETE_PURCHASEBILL_SUCCESS = "DELETE_PURCHASEBILL_SUCCESS";
export const DELETE_PAYOUTBILL_SUCCESS = "DELETE_PAYOUTBILL_SUCCESS";
export const DELETE_PURCHASEORDER_SUCCESS = "DELETE_PURCHASEORDER_SUCCESS";
export const DELETE_PURCHASERETURN_SUCCESS = "DELETE_PURCHASERETURN_SUCCESS";

export const UPDATE_PURCHASEBILL_SUCCESS = "UPDATE_PURCHASEBILL_SUCCESS";
export const UPDATE_PAYOUTBILL_SUCCESS = "UPDATE_PAYOUTBILL_SUCCESS";
export const UPDATE_PURCHASEORDER_SUCCESS = "UPDATE_PURCHASEORDER_SUCCESS";
export const UPDATE_PURCHASERETURN_SUCCESS = "UPDATE_PURCHASERETURN_SUCCESS";

// export const UPDATE_PROFILE_SUCCESS = "UPDATE_PROFILE_SUCCESS";
// export const FETCH_COMPANIES_SUCCESS = "FETCH_COMPANIES_SUCCESS";
// export const INPUTCHANGE = "INPUTCHANGE";
