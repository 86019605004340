import React from "react";

const FixedAssets = () => {
   return (
      <div style={{ padding: "80px 50px" }}>
         <p style={{width:"600px", padding:"10px 80px", textAlign:"center"}}>
            Watch this video to understand how to manage Fixed Assets in Vyapar?
         </p>
         <div>
            <iframe
               width="686"
               height="386"
               src="https://www.youtube.com/embed/TXWOxCQPuvU"
               title="Fixed Assets Kaise add kare II Desktop"
               frameborder="0"
               allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
               referrerpolicy="strict-origin-when-cross-origin"
               allowfullscreen
            ></iframe>
         </div>
      </div>
   );
};

export default FixedAssets;
