export const FETCH_PARTIES_LOADING = "FETCH_PARTIES_LOADING";
export const FETCH_PARTIES_ERROR = "FETCHPARTIES_ERROR";
export const FETCH_PARTIES_SUCCESS = "FETCH_PARTIES_SUCCESS";
export const CHANGE_CURRENT_PARTY = "CHANGE_CURRENT_PARTY";

export const SAVE_PARTY_LOADING = "SAVE_PARTY_LOADING";
export const SAVE_PARTY_ERROR = "SAVE_PARTY_ERROR";
export const SAVE_PARTY_SUCCESS = "SAVE_PARTY_SUCCESS";

export const FETCH_GROUP_LOADING = "FETCH_GROUP_LOADING";

export const PARTIES_POST_REQUEST = "PARTIES_POST_REQUEST";
export const PARTIES_POST_SUCCESS = "PARTIES_POST_SUCCESS";
export const PARTIES_POST_FAILED = "PARTIES_POST_FAILED";

// Edit Party action types
export const EDIT_PARTY_LOADING = "EDIT_PARTY_LOADING";
export const EDIT_PARTY_ERROR = "EDIT_PARTY_ERROR";
export const SUCCESS_EDIT_PARTY = "SUCCESS_EDIT_PARTY";

// Delete Party action types
export const LOADING_DELETE_PARTY = "LOADING_DELETE_PARTY";
export const ERROR_DELETE_PARTY = "ERROR_DELETE_PARTY";
export const SUCCESS_DELETE_PARTY = "SUCCESS_DELETE_PARTY";

// Get Current Party Data action types
export const LOADING_GET_CURRENT_PARTY = "LOADING_GET_CURRENT_PARTY";
export const ERROR_GET_CURRENT_PARTY = "ERROR_GET_CURRENT_PARTY";
export const SUCCESS_GET_CURRENT_PARTY = "SUCCESS_GET_CURRENT_PARTY";

// Others
export const PARTIES_PURCHASE_BILL_REQUEST = "PARTIES_PURCHASE_BILL_REQUEST";
export const PARTIES_PURCHASE_BILL_FAILURE = "PARTIES_PURCHASE_BILL_FAILURE";
export const PARTIES_PAYMENT_OUT_SUCCESS = "PARTIES_PAYMENT_OUT_SUCCESS";
export const PARTIES_PAYMENT_OUT_REQUEST = "PARTIES_PAYMENT_OUT_REQUEST";
export const PARTIES_PAYMENT_OUT_FAILURE = "PARTIES_PAYMENT_OUT_FAILURE";
